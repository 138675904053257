import { useEffect } from "react";
import { dispatchCusEvent, getAutoShortLang, getCHPYPapamByName, setCookie } from "../utils/utils";
import useLanguage, { ENUM_LANG_LISTENER, languages } from "./site/useLanguage";
import { useEventListener } from "usehooks-ts";

export const cookieLangByLang = {
  ru: "ru_RU",
  en: "en_US",
  es: "es_ES",
};

export default function useCommonHooks() {
  const [, setSelectLang] = useLanguage();

  useEffect(() => {
    const campaignId = getCHPYPapamByName("campaign_id");
    if (!campaignId) return;

    setCookie("campaign_id", campaignId);
  }, []);

  useEffect(() => {
    const lang = getCHPYPapamByName("lang");
    if (!lang) return;

    if (lang === "auto") {
      dispatchCusEvent(ENUM_LANG_LISTENER.auto);
      return;
    }
    setSelectLang(lang);
  }, []);

  useEventListener(ENUM_LANG_LISTENER.auto, () => {
    const short = getAutoShortLang();
    const lang = languages.find((el) => el.code === short)?.lang;

    setSelectLang(lang);
  });
}
