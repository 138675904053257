import { useEffect } from "react";

import i18n from "../../i18n";
import { useCookies } from "react-cookie";
import { getShortLang } from "../../utils/utils";

export const ENUM_LANG_LISTENER = {
  auto: "auto",
};

export const languages = [
  { title: "English", flag: "us", code: "en", country: "US", lang: "en_US" },
  { title: "Русский", flag: "ru", code: "ru", country: "RU", lang: "ru_RU" },
  { title: "Español", flag: "es", code: "es", country: "ES", lang: "es_ES" },
];

export default function useLanguage() {
  const shortLang = getShortLang();

  const chosenLang = languages.find((el) => el.code === shortLang)?.lang;

  const [{ language: cookieLang }, setCookieLang] = useCookies(["language"]);

  useEffect(() => {
    // если в куке есть, то больше не заполняем
    if (cookieLang && cookieLang !== "undefined") return;

    setCookieLang("language", chosenLang, { path: "/" });
  }, [cookieLang, chosenLang]);

  useEffect(() => {
    const code = cookieLang?.split?.("_")?.[0];
    if (!code) return;
    i18n.changeLanguage(code);
  }, [cookieLang]);

  return [cookieLang, (lang) => setCookieLang("language", lang, { path: "/" })];
}
