import React from "react";
import "../cookie/cookie.scss";
import PolicyContentRU from "./PolicyContentRU";
import PolicyContentEN from "./PolicyContentEN";
import useLanguage from "../../../hooks/site/useLanguage";

export default function Policy() {
  const [selectLang] = useLanguage();

  return (
    <section className="cookie">
      {selectLang === "ru" ? <PolicyContentRU /> : <PolicyContentEN />}
    </section>
  );
}
