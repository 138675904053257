import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./locales/en/en.json";
import RUS from "./locales/ru/ru.json";
import ES from "./locales/es/es.json";
import { getShortLang } from "./utils/utils";

// the translations
const resources = {
  en: { translation: EN },
  ru: { translation: RUS },
  es: { translation: ES },
  it: { translation: EN },
  de: { translation: EN },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getShortLang(),
  // debug: false,
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
